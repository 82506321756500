export default {
    name: "neo-user-card",
    components: {},
    props: ["name", "email", "checked"],
    data() {
        return {
            // checked: false,
        };
    },
    computed: {},
    mounted() {},
    methods: {
        onClick() {
            // this.checked = !this.checked
            this.$emit("click");
        },
    },
};
