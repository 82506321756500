export default {
    name: "neo-alert",
    components: {},
    props: ["success", "error"],
    data() {
        return {};
    },
    computed: {},
    async mounted() {},
};
