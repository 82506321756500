import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import Modal from "@/components/modal";
import Tab from "@/components/tab";
import Table from "@/components/table";
import Confirmation from "@/components/confirmation";
import Loader from "@/components/loader";
import Checkbox from "@/components/checkbox";
import CheckboxRound from "@/components/checkbox-round";
import UserPermissions from "@/components/user-permissions";
import UserCard from "@/containers/cards/user";
import EditPermission from "@/containers/models/edit-permission";
import Tag from "@/components/tag";
import AlertBox from "@/containers/alert-box";
import axios from "../../axios/user";
import caseApi from "@/axios";
import Button from "../../components/button";
import Input from "../../components/input";
import Multiselect from "@/components/vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import Vue from "vue";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import {blackboxApi} from "../../main";
import subSidepanel from "@/components/sub-sidepanel";
import {default as saasAxios} from "../../axios";

Vue.use(Toast);

export default {
    components: {
        "neo-modal": Modal,
        "neo-input": Input,
        "neo-tag": Tag,
        "neo-alert": AlertBox,
        "neo-table": Table,
        "neo-confirmation": Confirmation,
        multiselect: Multiselect,
        "neo-button": Button,
        "neo-tab": Tab,
        "neo-user-card": UserCard,
        "neo-loader": Loader,
        "neo-checkbox": Checkbox,
        "edit-permission": EditPermission,
        "checkbox-round": CheckboxRound,
        "font-awesome-icon": FontAwesomeIcon,
        "user-permisions": UserPermissions,
        subSidepanel,
    },
    name: "groups",
    mixins: [validationMixin],
    validations: {
        // addPermissionFilter: { required},
        newGroupName: {required},
        // searchAddUser: { required},
        // searchPermissionFilter: { required },
        // searchGroupFilter: { required},
        // searchUserFilter: { required },
    },
    data() {
        return {
            AccountCases: {},
            searchUserFilter: "",
            searchGroupFilter: "",
            searchPermissionFilter: "",
            editPermission: null,
            hoveredGroup: {},
            groupUserToDelete: [],
            searchAddUser: "",
            addPermissionFilter: "",
            selectedDeleteUser: [],
            deleteUserDialog: false,
            accountUri: "",
            groupBaseUri: "",
            accountUsers: [],
            newGroupUser: [],
            addGroup: false,
            deleteGroupId: null,
            deleteGroupIndex: null,
            newGroupName: "",
            addPermissions: false,
            addUser: false,
            checkedResources: [],
            newPermissions: {},
            selectedGroupPermissions: [],
            allGroups: [],
            permissionHeaders: [
                {
                    text: "Resource",
                    value: "resource",
                },
                {
                    text: "Permission",
                    value: "permissions",
                },
            ],
            itemKey: "id",
            selectable: false,
            hasAction: true,
            selectedGroup: null,
            allResources: [],
            allActions: [],
            checkedAction: [],
            tabs: [
                {key: "Users", name: "Users", enabled: true},
                {key: "Permissions", name: "Permissions", enabled: true},
            ],
            currentTab: "Users",
            selectedGroupUsers: [],
            loaderController: {
                loading: false,
                success: false,
                groups: false,
                users: false,
                permissions: false,
            },
            alertMessage: {
                success: null,
                error: null,
            },
            allResources: [],
            allResourcesPerm: [],
            selectedResource: null,
            selectedResourcePerm: "",
            allCases: [],
            selectedCases: [],
            allPermissions: [],
            selectedPermissions: "",
        };
    },
    computed: {
        getSelectedGroupUsers() {
            let users = [];
            this.selectedGroupUsers.forEach((user) => {
                users.push({
                    id: user.id,
                    name: user.first_name + " " + user.last_name,
                    email: user.email,
                    image: require("@/assets/icons/graph/user_1.png"),
                    alignedCases: user.alignedCases,
                });
            });
            return users;
        },
        colWidth() {
            let cols = this.headers.length;
            if (this.selectable) {
                cols += 1;
            }
            if (this.hasAction) {
                cols += 1;
            }
            return `${100 / cols}%`;
        },
        modalTitle() {
            let title = "";
            if (this.addGroup) title = "Add Group";
            else if (this.addUser) title = "Add User";
            else if (this.addPermissions) title = "Add Permissions";
            return title;
        },

        modalSubmitText() {
            let title = "";
            if (this.addGroup) title = "Create Group";
            else if (this.addUser) title = "Create User";
            else if (this.addPermissions) title = "Save Permissions";
            return title;
        },

        getNonGroupResources() {
            const resources = [];
            for (const rs of this.allResources) {
                const matched = this.selectedGroupPermissions.find(({resource}) => resource === rs.name);
                if (!matched) resources.push(rs);
            }
            return resources;
        },

        getNonGroupUser() {
            const users = [];
            for (const ur of this.accountUsers) {
                const matched = this.selectedGroupUsers.find(({id}) => id === ur.id);
                if (!matched) users.push(ur);
            }
            return users;
        },
        groupPermissionToAdd() {
            const nw_pr = [];
            for (const ac of this.allActions) {
                const matched = this.editPermission.permissions.find(({action}) => action === ac.name);
                if (!matched) {
                    nw_pr.push(ac);
                }
            }
            return nw_pr;
        },
    },
    async mounted() {
        await this.getAccountCases();
        const acc_resp = await axios.get("api/v1/accounts");
        this.accountUri = `api/v1/accounts/${this.$store.getters.getAccountId}`;
        this.groupBaseUri = `api/v1/accounts/${this.$store.getters.getAccountId}/groups`;
        await this.getAllGroups();
        if (this.allGroups.length) {
            this.selectedGroup = this.allGroups[0];
            this.onSelectGroup(this.selectedGroup);
        }

        try {
            const response = await axios.get(`${this.accountUri}/resources`);
            this.allResources = response.data;
        } catch (error) {
            let message = error.message;
            this.$toast.error(message);
        }

        await this.getAllActions();
    },
    methods: {
        async getAccountCases() {
            const caseListUrl = "/api/v1/case-info/case-list";
            // this.account_id = acc_resp.data.id;
            // this.accountUri = `/api/v1/accounts/${acc_resp.data.id}`;
            try {
                // const users_url = `${this.accountUri}/users`;
                const cases = await blackboxApi.get(caseListUrl);
                this.AccountCases = cases.data.cases;
                // this.users = users_resp.data;

                // this.users.forEach(function (user) {
                // //   user.full_name =
                // //     user.first_name.charAt(0).toUpperCase() +
                // //     user.first_name.slice(1) +
                // //     ' ' +
                // //     user.last_name.charAt(0).toUpperCase() +
                // //     user.last_name.slice(1);
                // });
                // this.resetLoader();
                return cases;
            } catch (error) {
                this.resetLoader();
                let message = error.message;
                this.$toast.error(message);
            }
        },

        async getAllActions() {
            try {
                const response = await axios.get("api/v1/actions");
                this.allActions = response.data;
            } catch (error) {
                let message = error.message;
                this.$toast.error(message);
            }
        },

        async getAllGroups() {
            this.loaderController.groups = true;
            try {
                const response = await axios.get(this.groupBaseUri);
                this.allGroups = response.data;
                this.loaderController.groups = false;
            } catch (error) {
                let message = error.message;
                this.$toast.error(message);
                this.loaderController.groups = false;
            }
        },

        submit() {
            if (this.addGroup) this.createGroup();
            else if (this.addUser) this.saveNewUser();
            else if (this.addPermissions) this.saveNewPermissions(this.newPermissions);
        },

        cancel() {
            if (this.addGroup) this.addGroup = !this.addGroup;
            else if (this.addUser) this.addUser = !this.addUser;
            else if (this.addPermissions) this.addPermissions = !this.addPermissions;
        },

        startLoader() {
            this.loaderController.success = false;
            this.loaderController.loading = true;
        },
        successLoader() {
            this.loaderController.success = true;
            this.loaderController.loading = false;
        },
        resetLoader() {
            this.loaderController.success = false;
            this.loaderController.loading = false;
        },
        cleanGroupData() {
            this.selectedGroup = null;
            this.selectedDeleteUser = [];
            this.selectedGroupPermissions = [];
            this.selectedGroupUsers = [];
            this.newPermissions = {};
            this.checkedAction = [];
            this.checkedResources = [];
        },
        async getUsersCases(userIds) {
            const url = `/permissions/user-case-mapping`;
            try {
                let response = await caseApi.post(url, {user_ids: userIds});
                return response.data;
            } catch (error) {
                return {};
            }
        },
        async onAsignCases(userId, casePermission) {
            const url = "/permissions";
            const payload = {
                user_id: userId,
                permission_name: casePermission.selectedPermission,
                resource: "case",
                case_ids: casePermission.selectedCases.map((el) => el._id),
            };
            try {
                const responce = await caseApi.post(url, payload);
                for (let sgu of this.selectedGroupUsers) {
                    if (sgu.id === userId) {
                        let addedPermissions = responce.data.permissions.map((pr) => {
                            return {
                                _id: pr.id,
                                name: pr.case_id,
                                permission: pr.permission_name,
                            };
                        });
                        sgu.alignedCases.push(...addedPermissions);
                    }
                }
            } catch (error) {}
        },
        handleGroupClick($event) {},
        beforeOpen() {
            if (!this.allResources.length || !this.allResourcesPerm.length || this.allCases.length) {
                this.fetchAllResources();
                this.fetchAllResourceScope();
                this.fetchAllpermissions();
                this.fetchAllCases();
            } else return;
        },
        openGroupAssignModel() {
            this.$modal.show("assign_group_case");
        },
        async fetchAllResources() {
            const {data} = await saasAxios.get("resources");
            const resources = data.map((e) => ({
                ...e,
                name: e.name
                    .split("_")
                    .join(" ")
                    .toString()
                    .replace(/^\w/, (c) => c.toUpperCase()),
            }));
            this.allResources = resources;
        },
        async fetchAllpermissions() {
            const {data} = await saasAxios.get("resources/scopes/case");
            // const resources = data
            this.allPermissions = data;
        },
        async fetchAllResourceScope() {
            const {data} = await saasAxios.get("resources/scopes");
            const permisions = data.filter((e) => !e.includes("case")).map((e) => e.toUpperCase());
            this.allResourcesPerm = permisions;
        },
        async fetchAllCases() {
            const {data} = await saasAxios.get("case-info/case-list");
            this.allCases = data.cases.map((e) => e.case_id);
        },
        async assignGroupResources() {
            const obj = {
                group_id: this.selectedGroup.id,
                permission_name: this.selectedResourcePerm.toLowerCase(),
                resource: this.selectedResource.name,
                case_ids: this.selectedCases,
            };
            const resp = await saasAxios.post("permissions/group", obj);
        },
        async onSelectGroup(group) {
            this.cleanGroupData();
            this.selectedGroup = group;
            this.selectedDeleteUser = [];
            try {
                this.loaderController.users = true;
                const url = `${this.groupBaseUri}/${group.id}/users`;
                const response = await axios.get(url);
                let groupUsers = response.data;
                let usersCases = await this.getUsersCases(groupUsers.map((gu) => gu.id));
                this.selectedGroupUsers = groupUsers.map((gu) => {
                    return {...gu, alignedCases: usersCases[gu.id] ? usersCases[gu.id] : []};
                });
                this.loaderController.users = false;
            } catch (error) {
                let message = error.message;
                this.$toast.error(message);
                this.loaderController.users = false;
            }

            try {
                this.loaderController.permissions = true;
                const url = `${this.groupBaseUri}/${group.id}/permissions`;
                const response = await axios.get(url);
                this.selectedGroupPermissions = response.data;
                this.selectedGroupPermissions.forEach(function (permission, index) {
                    permission.index = index;
                });
                this.loaderController.permissions = false;
            } catch (error) {
                let message = error.message;
                this.$toast.error(message);
                this.loaderController.permissions = false;
            }
        },

        async createGroup() {
            if (this.newGroupName) {
                try {
                    this.startLoader();
                    await axios.post(this.groupBaseUri, {name: this.newGroupName});
                    this.successLoader();
                    this.$toast.success("Group created successfully");
                    this.addGroup = !this.addGroup;
                    this.resetLoader();
                    await this.getAllGroups();
                    if (this.allGroups.length) {
                        this.selectedGroup = this.allGroups[this.allGroups.length - 1];
                        this.onSelectGroup(this.selectedGroup);
                    }
                } catch (error) {
                    let message = error.message;
                    this.$toast.error(message);
                    this.addGroup = !this.addGroup;
                    this.resetLoader();
                }
            }
        },

        async deleteGroup() {
            const url = `${this.groupBaseUri}/${this.selectedGroup.id}`;
            try {
                let responce = await axios.delete(url);
                this.$toast.success(`Group deleted successfully! >>> ${responce}`);
                this.getAllGroups();

                if (this.selectedGroup.id === this.deleteGroupId) {
                    this.selectedGroup = this.allGroups[this.deleteGroupIndex - 1];
                    this.onSelectGroup(this.selectedGroup);
                } else {
                    if (this.allGroups.length) {
                        this.selectedGroup = this.allGroups[0];
                        this.onSelectGroup(this.selectedGroup);
                    }
                }
                this.deleteGroupIndex = null;
                this.deleteGroupId = null;
            } catch (error) {
                this.deleteGroupIndex = null;
                this.deleteGroupId = null;
                if (error.response.status === 400) {
                    this.$toast.error(error.response.data.detail);
                } else {
                    this.$toast.error(error.message);
                }
            }
        },
        toggleAlertBox({success = null, error = null}) {
            this.alertMessage.success = success;
            this.alertMessage.error = error;
            setTimeout(() => {
                this.alertMessage.success = null;
                this.alertMessage.error = null;
            }, 3000);
        },
        async toggleAddUser() {
            this.addUser = true;
            this.newGroupUser = [];
            if (this.accountUsers.length === 0) {
                try {
                    const response = await axios.get(`${this.accountUri}/users?status=CONFIRMED`);
                    this.accountUsers = response.data;
                } catch (error) {
                    let message = error.message;
                    this.$toast.error(message);
                }
            }
        },
        addNewUser(user) {
            this.newGroupUser.push(user);
        },
        async saveNewUser() {
            if (this.newGroupUser.length !== 0) {
                try {
                    const url = `${this.groupBaseUri}/${this.selectedGroup.id}/users`;
                    await axios.post(url, this.newGroupUser);
                    this.newGroupUser = [];
                    this.$toast.success("User added successfully!");
                    this.getAllGroups();
                    this.onSelectGroup(this.selectedGroup);
                    this.addUser = !this.addUser;
                    this.resetLoader();
                } catch (error) {
                    let message = error.message;
                    this.$toast.error(message);
                    this.addUser = !this.addUser;
                    this.resetLoader();
                }
            }
        },
        addNewPermission(resourceName) {
            if (this.newPermissions[resourceName]) {
                delete this.newPermissions[resourceName];
            } else {
                this.newPermissions[resourceName] = [];
            }
        },

        async saveNewPermissions(newPermissions) {
            const pr_copy = JSON.parse(JSON.stringify(newPermissions));
            const pr_data = [];
            for (const [key, value] of Object.entries(pr_copy)) {
                for (const prName of value) {
                    let name = prName.split("_");
                    let action = name[name.length - 1];
                    pr_data.push({resource: key, action: action});
                }
            }
            await this.savePermissions(pr_data);
        },
        async savePermissions(pr_data) {
            try {
                const url = `${this.groupBaseUri}/${this.selectedGroup.id}/permissions`;
                await axios.post(url, pr_data);

                this.resetLoader();
                this.addPermissions = false;
                this.editPermission = null;
                this.$toast.success("Permissions added successfully!");
                this.onSelectGroup(this.selectedGroup);
            } catch (error) {
                let message = error.message;
                this.$toast.error(message);
                this.addPermissions = !this.addPermissions;
                this.resetLoader();
            }
        },

        onEditPermissions(resource, permissions) {
            this.checkedResources = [];
            let that = this;
            permissions.forEach(function (permission) {
                that.checkedResources.push(permission.action);
            });
            this.editPermission = {
                resource,
                permissions,
            };
        },
        async saveEditedPermissions(resource, permissions) {
            const pr_data = [];
            for (const pr of permissions) {
                pr_data.push({resource, action: pr});
            }
            await this.savePermissions(pr_data);
        },
        seletUserToDelete(user) {
            this.deleteUserDialog = true;
            if (this.selectedDeleteUser.includes(user.id)) {
                const userIndex = this.selectedDeleteUser.indexOf(user.id);
                this.selectedDeleteUser.splice(userIndex, 1);
            } else {
                this.selectedDeleteUser.push(user.id);
            }
        },
        async deleteUser() {
            let delete_ids = "";
            for (const pr_id of this.selectedDeleteUser) {
                delete_ids = `${delete_ids}users=${pr_id}&`;
            }
            try {
                await axios.delete(`${this.groupBaseUri}/${this.selectedGroup.id}/users?${encodeURI(delete_ids)}`);
                this.selectedDeleteUser = [];
                this.deleteUserDialog = false;
                this.$toast.success("Selected users deleted successfully!");
                await this.getAllGroups();
                this.onSelectGroup(this.selectedGroup);
            } catch (error) {
                this.selectedDeleteUser = [];
                this.deleteUserDialog = false;
                let message = error.message;
                this.$toast.error(message);
            }
        },
        async deleteAction(resource, action, id, action_index, pr_index) {
            const payload = {resource, action, id};
            try {
                const url = `${this.groupBaseUri}/${this.selectedGroup.id}/permissions`;
                let response = await axios.delete(url, {data: payload});
                this.selectedGroupPermissions[pr_index].permissions.splice(action_index, 1);
                if (this.selectedGroupPermissions[pr_index].permissions.length === 0) {
                    this.selectedGroupPermissions.splice(pr_index, 1);
                }
                this.$toast.success("Permissions updated successfully!");
            } catch (error) {
                let message = error.message;
                this.$toast.error(message);
            }
        },
        async handleAssignCase() {
            if (this.selectedPermissions && this.selectedCases.length) {
                try {
                    const case_ids = this.selectedCases;
                    const obj = {
                        group_id: this.selectedGroup.id,
                        permission_name: this.selectedPermissions.name,
                        resource: "case",
                        case_ids: case_ids,
                    };
                    let res = await saasAxios.post("permissions/group", obj);
                    this.selectedCases = [];
                    this.selectedPermissions = null;
                    this.$modal.hide("assign_group_case");
                    this.$toast.success(`Case Assigned to ${this.selectedGroup.name}`);
                } catch (error) {
                    this.$toast.error(error);
                }
            } else {
                this.$toast.error(`Please select cases and permissions`);
                return;
            }
        },
    },
};
