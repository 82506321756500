import Button from "@/components/button";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "neo-confirmation",
    components: {
        "neo-button": Button,
        "font-awesome-icon": FontAwesomeIcon,
    },
    props: ["title", "description", "isDestroying"],
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {},
};
