import Button from "@/components/button";
import CheckboxRound from "@/components/checkbox-round";

export default {
    name: "edit-permission",
    components: {
        "neo-button": Button,
        "checkbox-round": CheckboxRound,
    },
    props: ["resource", "permissions"],
    data() {
        return {
            checkedAction: [],
        };
    },
    computed: {},
    mounted() {},
    methods: {},
};
