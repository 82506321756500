import VueMultiselect from "@/components/vue-multiselect";
import {blackboxApi} from "../../main";

export default {
    name: "user-permissions",
    components: {
        VueMultiselect,
    },
    props: ["cases", "people"],
    data() {
        return {
            casePermissions: {},
            selectedCases: null,
            selectedPermission: null,
            // people: [
            //     {
            //         name: "Harvey Specter",
            //         email: "harvey.specter@example.com",
            //         image: require("@/assets/icons/graph/user_1.png"),
            //         alignedCases: ["CASE-1", "CASE-2"],
            //     },
            //     // More people...
            // ],
            options: [],
            formCasesInput: [],
            selectedUser: null,
        };
    },
    computed: {},
    async mounted() {
        await this.getCasePermissions();
    },
    methods: {
        async AssignCaseToUser() {
            const casePermissionsUrl = "/api/v1/permissions";
            if (this.SelectedPermission === null) {
                alert("invalid permission");
            }
            const PERMISSION_TYPE = "case";
            const caseIds = this.selectedCases.map((e) => e.id);

            const permissionData = {
                user_id: "680da78067e143daa5b9a2000c9357b2",
                permission_name: casePermissions.name,
                resource: PERMISSION_TYPE,
                case_ids: caseIds,
            };
            try {
                const permissions = await blackboxApi.post(casePermissionsUrl, permissionData);
            } catch (error) {
                this.resetLoader();
                let message = error.message;
                this.$toast.error(message);
            }
        },
        async getCasePermissions() {
            const casePermissionsUrl = "/api/v1/resources/scopes/case";

            try {
                const permissions = await blackboxApi.get(casePermissionsUrl);
                this.casePermissions = permissions.data;
            } catch (error) {
                this.resetLoader();
                let message = error.message;
                this.$toast.error(message);
            }
        },
        handleAlignCases(user) {
            this.selectedUser = user;
            this.$modal.show("align-cases");
        },
        limitText(length) {
            return ` +${length}`;
        },
        addCasesTag(newTag) {
            const tag = this.getTag(newTag);
            this.options.push(tag);
            this.formCasesInput.push(tag);
        },
        getTag(tag) {
            return {
                name: tag,
                code: tag.substring(0, 2) + Math.floor(Math.random() * 10000000),
            };
        },
        handleSave() {
            this.$emit("onAsignCasePermission", this.selectedUser.id, {selectedCases: this.selectedCases, selectedPermission: this.selectedPermission.name});
            this.$modal.hide("align-cases");
        },
    },
};
