import { render, staticRenderFns } from "./edit.html?vue&type=template&id=163bcb82&scoped=true&"
import script from "./edit.js?vue&type=script&lang=js&"
export * from "./edit.js?vue&type=script&lang=js&"
import style0 from "./edit.scss?vue&type=style&index=0&id=163bcb82&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "163bcb82",
  null
  
)

export default component.exports